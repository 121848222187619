<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>2581C2E1-A182-4254-903C-B84A6495DC57</title>
    <defs>
      <path
        d="M9.33333333,4 L6.66666667,4 L6.66666667,0 L2.66666667,0 L2.66666667,4 L0,4 L4.66666667,8.66666667 L9.33333333,4 Z M4,5.33333333 L4,1.33333333 L5.33333333,1.33333333 L5.33333333,5.33333333 L6.11333333,5.33333333 L4.66666667,6.78 L3.22,5.33333333 L4,5.33333333 Z M0,10 L9.33333333,10 L9.33333333,11.3333333 L0,11.3333333 L0,10 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      id="Pro-0.1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="08-01-01-Subjects-HiPAL-PRO-Pro-0.1"
        transform="translate(-1030.000000, -164.000000)"
      >
        <g
          id="Buttons-/-Thirdly-01-/-Ico+Text-/-Small-/-Disabled"
          transform="translate(1014.000000, 152.000000)"
        >
          <g id="ico-/-download" transform="translate(16.000000, 12.000000)">
            <rect id="ViewBox" x="0" y="0" width="16" height="16"></rect>
            <g id="Color-/-900" transform="translate(3.333333, 2.666667)">
              <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
              </mask>
              <use
                id="Mask"
                fill="#000000"
                fill-rule="nonzero"
                xlink:href="#path-1"
              ></use>
              <g id="Color-/-Blue-Gray-100" mask="url(#mask-2)" fill="#CFD8DC">
                <g transform="translate(-3.333333, -2.666667)" id="Rectangle">
                  <rect x="0" y="0" width="16" height="16"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
