import { useStore } from "@/store/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";

import isEqual from "lodash-es/isEqual";

import { SortingDirection } from "@/interfaces/SortingItem";

import useUserRoles, { UserRole } from "@/uses/useUserRoles";

import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";
import { SortingItem } from "@/interfaces/SortingItem";

import TableHeaderComponent from "@/components/TableHeaderComponent/TableHeaderComponent.vue";
import SearchInput from "@/components/SearchInput/SearchInput.vue";
import DrawerContent from "./DrawerContent/DrawerContent.vue";

import CancelIcon from "@/assets/icons/Cancel.vue";
import PlayCircleIcon from "@/assets/icons/PlayCircle.vue";
import InfoIcon from "@/assets/icons/Info.vue";
import DownloadIcon from "@/assets/icons/Download.vue";
import FilterIcon from "@/assets/icons/Filter.vue";

import { getSitesForFilter } from "@/api/SitesApi";
import { getSitesFilterItem } from "@/utils/filtersUtils";
import { showError, showErrorOptional } from "@/utils/notificationUtils";
import {
  AssessmentType,
  CodeStatus,
  HiPALSubjectItem,
  SubjectItem,
} from "@/api/interfaces/SubjectsInterfaces";
import { PresetItem, Sex } from "@/api/SitePresetsApi";
import {
  getHiPALSubjectsSearch,
  getJSON,
  revokeAssesment,
  startNewAssesment,
} from "@/api/SubjectsApi";
import useSexFormatter from "@/uses/useSexFormatter";
import { RangeFilterItem } from "@/components/RangeFilter/RangeFilter";
import useDateFormatter from "@/uses/useDateFormatter";
import { SearchRequestParams } from "@/interfaces/SearchRequestParams";

export default defineComponent({
  name: "HipalProSubjects",
  props: {},
  components: {
    DrawerContent,
    SearchInput,
    TableHeaderComponent,
    CancelIcon,
    PlayCircleIcon,
    InfoIcon,
    DownloadIcon,
    FilterIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const fieldNames = {
      id: "id",
      subject_id: "subject_id",
      site_id: "site_id",
      site_name: "site_name",
      sex_type: "sex_type",
      age: "age",
      assessment_id: "assessment_id",
      score_mmse: "score_mmse",
      score_adascog: "score_adascog",
      assessment_time: "assessment_timestamp",
    };

    const list = ref<{
      data: HiPALSubjectItem[];
      selected: HiPALSubjectItem[];
      isAll: boolean;
    }>({
      data: [],
      selected: [],
      isAll: false,
    });

    const state = reactive({
      search: "",
      filtersData: {
        sex_type: {
          items: [
            {
              options: [
                {
                  value: Sex.Male,
                  text: "Male",
                },
                {
                  value: Sex.Female,
                  text: "Female",
                },
              ],
            },
          ],
          values: [],
        } as FilterObject,
        sites: {
          items: [],
          values: [],
        } as FilterObject,
        age: {
          from: "",
          to: "",
          min: 18,
          max: 120,
        } as RangeFilterItem,
        score_mmse: {
          from: "",
          to: "",
          min: 0,
          max: 30,
        } as RangeFilterItem,
        score_adascog: {
          from: "",
          to: "",
          min: 0,
          max: 70,
        } as RangeFilterItem,
      },
      filterPreset: null as PresetItem | null,
      paging: {
        page: 1,
        pageSize: 30,
        total: 0,
      },
      sorting: null as SortingItem[] | null,
      isLoading: false,
      drawer: false,
    });

    const createFilterObject = () => {
      const filter = {} as any;
      if (state.filtersData.age.from)
        filter.age_from = state.filtersData.age.from;
      if (state.filtersData.age.to) filter.age_to = state.filtersData.age.to;
      if (state.filtersData.score_mmse.from)
        filter.score_mmse_from = state.filtersData.score_mmse.from;
      if (state.filtersData.score_mmse.to)
        filter.score_mmse_to = state.filtersData.score_mmse.to;
      if (state.filtersData.score_adascog.from)
        filter.score_adascog_from = state.filtersData.score_adascog.from;
      if (state.filtersData.score_adascog.to)
        filter.score_adascog_to = state.filtersData.score_adascog.to;

      return {
        filters: [
          {
            field: fieldNames.sex_type,
            values: state.filterPreset?.sex_types?.length
              ? state.filterPreset?.sex_types
              : state.filtersData.sex_type.values,
          },
          {
            field: fieldNames.site_id,
            values: state.filtersData.sites.values,
          },
        ].filter((f) => f.values.length),
        ...filter,
        ...state.filterPreset,
      };
    };

    const updateSubjects = async () => {
      state.isLoading = true;
      try {
        const subjects = await getHiPALSubjectsSearch({
          paging: {
            page_number: state.paging.page,
            amount_per_page: state.paging.pageSize,
          },
          search_pattern: state.search,
          sorting: state.sorting ? state.sorting : undefined,
          ...createFilterObject(),
        });
        state.paging.total = subjects.total;
        list.value.data = subjects.entities;
      } catch (ex) {
        showErrorOptional("Error while updating subjects", ex);
        console.log(ex);
      } finally {
        state.isLoading = false;
      }
    };

    watch(
      () => ({
        sex_type: state.filtersData.sex_type.values,
        sites: state.filtersData.sites.values,
        age_from: state.filtersData.age.from,
        age_to: state.filtersData.age.to,
        score_mmse_from: state.filtersData.score_mmse.from,
        score_mmse_to: state.filtersData.score_mmse.to,
        score_adascog_from: state.filtersData.score_adascog.from,
        score_adascog_to: state.filtersData.score_adascog.to,
      }),
      (newValue: any, oldValue: any) => {
        if (!isEqual(newValue, oldValue)) {
          updateSubjects();
        }
      }
    );

    const useRoles = useUserRoles(store);

    onBeforeMount(() => {
      if (useRoles.hasRole(UserRole.SystemAdmin, UserRole.Analyst)) {
        getSitesForFilter().then((sites) => {
          state.filtersData.sites.items = getSitesFilterItem(sites);
        });
      }

      updateSubjects();
    });

    return {
      state,
      list,
      fieldNames,
      ...useRoles,
      ...useSexFormatter(),
      ...useDateFormatter(),
      getSortingValueForField: computed(() => (field: string) => {
        if (state.sorting) {
          const sorting = state.sorting[0];
          return sorting.field === field ? sorting.direction : null;
        }
        return null;
      }),
      editSubject: (subject: HiPALSubjectItem) => {
        router.push(
          `/subjects/edit-subject/${
            subject.site_id ? `${subject.site_id}/` : ""
          }${subject.internal_subject_id}`
        );
      },
      viewSubject: (subject: HiPALSubjectItem) => {
        router.push(
          `/subjects/view-subject/${
            subject.site_id ? `${subject.site_id}/` : ""
          }${subject.internal_subject_id}`
        );
      },
      pageChange: (page: number) => {
        state.paging.page = page;
        updateSubjects();
      },
      searchChanged() {
        updateSubjects();
      },
      changeFilter(filter: FilterObject, value: any[]) {
        filter.values = value;
        updateSubjects();
      },
      sortingChange(field: string, direction: SortingDirection | null) {
        console.log("sortingChange", field);
        if (direction === null) {
          state.sorting = null;
        } else {
          state.sorting = [{ field, direction }];
        }
        updateSubjects();
      },
      selectAll(val: any) {
        list.value.isAll = !!val.length;
      },
      selectionChange(val: HiPALSubjectItem[]) {
        list.value.selected = val;
        list.value.isAll = false;
      },
      startNewAssesment(subject?: HiPALSubjectItem) {
        if (subject) {
          startNewAssesment(
            subject?.internal_subject_id as string,
            subject?.site_id
          );
        } else {
          list.value.selected.forEach((s) =>
            startNewAssesment(s?.internal_subject_id as string, s?.site_id)
          );
        }
      },
      goAssessment(subject: HiPALSubjectItem) {
        return {
          name: "View HiPAL Pro",
          params: {
            id: subject.internal_subject_id || "",
            siteId: subject.site_id || "",
            assessmentId: subject.assessment_id,
          },
        };
      },
      async downloadJSON() {
        console.log("download JSON");

        await getJSON(
          list.value.isAll
            ? []
            : list.value.selected.map((s) => s?.internal_subject_id),
          createFilterObject()
        );
      },
      openFilter() {
        state.drawer = true;
      },
      setFilterFromDrawer(filter: PresetItem) {
        state.filterPreset = filter;
        state.drawer = false;
        updateSubjects();
      },
      cancelFromDrawer() {
        state.drawer = false;
      },
      resetFilters() {
        state.filterPreset = null;
        updateSubjects();
      },
    };
  },
});
