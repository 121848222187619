import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "vue";

import { showError } from "@/utils/notificationUtils";
import {
  createPreset,
  editPreset,
  getPresetById,
  LogicalOperator,
  PresetFilterItem,
  presetFilters,
  PresetItem,
  searchPresets,
  Sex,
} from "@/api/SitePresetsApi";
import {
  PRESET_NAME_REQUIRED_MESSAGE,
  TO_BIGGER_THAN_FROM_MESSAGE,
} from "@/utils/validationMessages";
import {
  checkIsBetween,
  checkIsNumber,
  hasValue,
} from "@/utils/validationUtils";
import useFormUtils from "@/uses/useFormUtils";
import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";
import { getPresetsFilterItem } from "@/utils/filtersUtils";

import DropdownFilter from "@/components/DropdownFilter/DropdownFilter.vue";
import { useStore } from "@/store/store";

export default defineComponent({
  name: "DrawerContent",
  props: {
    siteId: String,
    show: Boolean,
  },
  components: { DropdownFilter },
  setup(props, { emit }) {
    const store = useStore();

    const nameForm = reactive({
      name: null as string | null,
    });

    const form = reactive({
      name: null as string | null,
      score_mmse_from: null,
      score_mmse_to: null,
      score_adascog_from: null,
      score_adascog_to: null,
      operator_type: null,
      age_from: null,
      age_to: null,
      sex_types: [],
    });
    if ((form.operator_type as any) === 0) {
      form.operator_type = null;
    }

    const rules = reactive({
      score_mmse_from: [
        {
          message: "Please enter MMSE score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_mmse_to);
          },
        },
        {
          message: "MMSE score From must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "MMSE score From must be between 0 and 30",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 30);
          },
        },
      ],
      score_mmse_to: [
        {
          message: "Please enter MMSE score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_mmse_from);
          },
        },
        {
          message: "MMSE score To must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "MMSE score To must be between 0 and 30",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 30);
          },
        },
        {
          message: TO_BIGGER_THAN_FROM_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (
              value &&
              form.score_mmse_from &&
              checkIsNumber(form.score_mmse_from)
            ) {
              return parseInt(value, 10) >= (form?.score_mmse_from || 0);
            } else {
              return true;
            }
          },
        },
      ],
      score_adascog_from: [
        {
          message: "Please enter ADAScog score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_adascog_to);
          },
        },
        {
          message: "ADAScog score From must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "ADAScog score From must be between 0 and 70",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 70);
          },
        },
      ],
      score_adascog_to: [
        {
          message: "Please enter ADAScog score",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return hasValue(value) || hasValue(form.score_adascog_from);
          },
        },
        {
          message: "ADAScog score To must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "ADAScog score To must be between 0 and 70",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 0, 70);
          },
        },
        {
          message: TO_BIGGER_THAN_FROM_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (
              value &&
              form.score_adascog_from &&
              checkIsNumber(form.score_adascog_from)
            ) {
              return parseInt(value, 10) >= (form?.score_adascog_from || 0);
            } else {
              return true;
            }
          },
        },
      ],
      operator_type: [
        {
          message: "Please enter operator",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            const needToFillOperator = !!(
              (form.score_mmse_from || form.score_mmse_to) &&
              (form.score_adascog_from || form.score_adascog_to)
            );

            return needToFillOperator && !value ? false : true;
          },
        },
        {
          message: "Please remove operator",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            const notNeedToFillOperator = !(
              (form.score_mmse_from || form.score_mmse_to) &&
              (form.score_adascog_from || form.score_adascog_to)
            );

            return notNeedToFillOperator && value ? false : true;
          },
        },
      ],
      age_from: [
        {
          message: "Age From must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "Age From must be between 18 and 120",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 18, 120);
          },
        },
      ],
      age_to: [
        {
          message: "Age To must be a number",
          trigger: "blur",
          validator: (rule: any, value: string) => {
            return checkIsNumber(value);
          },
        },
        {
          type: "number",
          message: "Age To must be between 18 and 120",
          validator: (rule: any, value: string) => {
            return checkIsBetween(value, 18, 120);
          },
        },
        {
          message: TO_BIGGER_THAN_FROM_MESSAGE,
          trigger: "blur",
          validator: (rule: any, value: string) => {
            if (value && form.age_from && checkIsNumber(form.age_from)) {
              return parseInt(value, 10) >= (form?.age_from || 0);
            } else {
              return true;
            }
          },
        },
      ],
    });

    const state = reactive({
      isSubmitted: false,
      loading: false,
      presets: {
        items: [],
        values: null,
      } as FilterObject,
      drawerOpen: false,
      savePopupOpen: false,
    });

    const presetsList = ref<PresetFilterItem[]>([]);

    const myPresets = ref<PresetItem[]>([]);
    const updatePresetsList = () => {
      if (store.state.auth.user) {
        const presetsFromStorage = localStorage.getItem(
          `my_presets_${store.state.auth.user.email}`
        );

        if (presetsFromStorage) {
          try {
            myPresets.value = JSON.parse(presetsFromStorage) as PresetItem[];
          } catch (ex) {
            console.log("issue with presets format in LocalStorage");
          }
        }
      }

      state.presets.items = getPresetsFilterItem(
        presetsList.value,
        myPresets.value
      );
    };

    const addToMyPresets = () => {
      myPresets.value.push(({ ...form, id: form.name } as any) as PresetItem);
      localStorage.setItem(
        `my_presets_${store.state.auth.user?.email}`,
        JSON.stringify(myPresets.value)
      );
      updatePresetsList();
      state.presets.values = form.name;
    };

    onBeforeMount(() => {
      presetFilters({
        paging: { page_number: 1, amount_per_page: 999999999 },
      }).then((presets) => {
        presetsList.value = presets.entities;
        updatePresetsList();
      });
    });

    watch(
      () => props.show,
      (newValue: boolean, oldValue: boolean) => {
        if (newValue) {
          state.drawerOpen = true;
        }
      }
    );

    const resetFilterFields = () => {
      form.name = null;
      form.score_mmse_from = null;
      form.score_mmse_to = null;
      form.operator_type = null;
      form.score_adascog_from = null;
      form.score_adascog_to = null;
      form.age_from = null;
      form.age_to = null;
      form.sex_types = [];
    };

    watch(
      () => state.presets.values,
      async (newValue: any, oldValue: any) => {
        // preset edit case
        if (newValue === "custom" || oldValue === "custom") {
          state.presets.values = null;
          return;
        }

        let preset: null | PresetItem = null;
        const presetItem = presetsList.value.find((p) => p.id === newValue);

        if (!presetItem) {
          preset = myPresets.value.find((p) => p.id === newValue) as PresetItem;
        } else {
          preset = presetItem
            ? await getPresetById(
                presetItem?.site_id || "",
                presetItem?.id || ""
              )
            : null;
        }

        if (preset) {
          form.name = preset.name as any;

          form.score_mmse_from = preset.score_mmse_from as any;
          form.score_mmse_to = preset.score_mmse_to as any;

          form.operator_type = preset.operator_type || (null as any);

          form.score_adascog_from = preset.score_adascog_from as any;
          form.score_adascog_to = preset.score_adascog_to as any;

          form.age_from = preset.age_from as any;
          form.age_to = preset.age_to as any;

          form.sex_types = preset?.sex_types as any;
        } else {
          resetFilterFields();
        }
      }
    );

    const presetForm = ref<any>(null);

    const mutateScores = () => {
      state.presets.values = "custom";
      form.name = "";
    };

    watch(
      () => form.operator_type,
      async (newValue: any, oldValue: any) => {
        if (newValue) {
          presetForm?.value?.clearValidate("operator_type");
          mutateScores();
        }
      }
    );

    const presetNameForm = ref<any>(null);

    const isRequiredFilled = computed(
      () =>
        !!(
          (form.score_adascog_from || form.score_adascog_to) &&
          (form.score_mmse_from || form.score_mmse_to)
        )
    );

    return {
      state,
      form,
      nameForm,
      rules,
      presetForm,
      presetNameForm,
      PRESET_NAME_REQUIRED_MESSAGE,
      scoreNames: { ADAScog: "ADAScog", MMSE: "MMSE" },
      ...useFormUtils(form),
      isRequiredFilled,
      resetFilterDisabled: computed(() => !state.presets.values),
      preset: computed(() => {
        const value = state.presets.values as string;

        let currentPreset: any = presetsList.value.find((s) => s.id === value);
        if (!currentPreset) {
          currentPreset = myPresets.value.find((s) => s.id === value);
        }

        return currentPreset?.name || "Nothing is selected";
      }),
      showSavePreset: computed(() => {
        return isRequiredFilled.value && !state.presets.values;
      }),
      submitForm() {
        presetForm.value.validate(async (valid: boolean) => {
          if (valid) {
            state.drawerOpen = false;
            emit("applyFilter", form);
          }
        });
      },
      clearFilters() {
        state.presets.values = null;
        resetFilterFields();
        presetForm?.value?.clearValidate();
      },
      drawerClosed() {
        emit("drawer-closed");
      },
      cancel() {
        state.presets.values = null;
        state.drawerOpen = false;
        emit("cancel");
      },
      savePreset() {
        presetForm.value.validate(async (valid: boolean) => {
          if (valid) {
            state.savePopupOpen = true;
          }
        });
      },
      mutateScores,
      confirmSavePreset() {
        presetNameForm.value.validate(async (valid: boolean) => {
          if (valid) {
            state.savePopupOpen = false;
            form.name = nameForm.name;
            nameForm.name = "";

            addToMyPresets();
          }
        });
      },
    };
  },
});
