<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="7ez9jy6qaa"
        d="M6.667 0C2.98 0 0 2.98 0 6.667c0 3.686 2.98 6.666 6.667 6.666 3.686 0 6.666-2.98 6.666-6.666C13.333 2.98 10.353 0 6.667 0zm0 12c-2.94 0-5.334-2.393-5.334-5.333 0-2.94 2.394-5.334 5.334-5.334C9.607 1.333 12 3.727 12 6.667 12 9.607 9.607 12 6.667 12zM9.06 3.333L6.667 5.727 4.273 3.333l-.94.94 2.394 2.394L3.333 9.06l.94.94 2.394-2.393L9.06 10l.94-.94-2.393-2.393L10 4.273l-.94-.94z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L16 0 16 16 0 16z"
              opacity=".87"
              transform="translate(-1207 -164) translate(1191 152) translate(16 12)"
            />
            <g
              transform="translate(-1207 -164) translate(1191 152) translate(16 12) translate(1.333 1.333)"
            >
              <mask id="ygrpjog3cb" fill="#fff">
                <use xlink:href="#7ez9jy6qaa" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#7ez9jy6qaa" />
              <g fill="#CFD8DC" mask="url(#ygrpjog3cb)">
                <path d="M0 0H16V16H0z" transform="translate(-1.333 -1.333)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
