<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="bqyekgwuia"
        d="M6.667 0C2.987 0 0 2.987 0 6.667c0 3.68 2.987 6.666 6.667 6.666 3.68 0 6.666-2.986 6.666-6.666S10.347 0 6.667 0zm0 12c-2.94 0-5.334-2.393-5.334-5.333 0-2.94 2.394-5.334 5.334-5.334C9.607 1.333 12 3.727 12 6.667 12 9.607 9.607 12 6.667 12zM5 9.667l4.667-3-4.667-3v6z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H16V16H0z"
              transform="translate(-1320 -164) translate(1304 152) translate(16 12)"
            />
            <g
              transform="translate(-1320 -164) translate(1304 152) translate(16 12) translate(1.333 1.333)"
            >
              <mask id="ugfihq7s6b" fill="#fff">
                <use xlink:href="#bqyekgwuia" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#bqyekgwuia" />
              <g fill="#CFD8DC" mask="url(#ugfihq7s6b)">
                <path d="M0 0H16V16H0z" transform="translate(-1.333 -1.333)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
