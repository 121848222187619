<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="s3eo6635la"
        d="M6 3.333h1.333v1.334H6V3.333zM6 6h1.333v4H6V6zm.667-6C2.987 0 0 2.987 0 6.667c0 3.68 2.987 6.666 6.667 6.666 3.68 0 6.666-2.986 6.666-6.666S10.347 0 6.667 0zm0 12c-2.94 0-5.334-2.393-5.334-5.333 0-2.94 2.394-5.334 5.334-5.334C9.607 1.333 12 3.727 12 6.667 12 9.607 9.607 12 6.667 12z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0L16 0 16 16 0 16z"
                transform="translate(-1079 -416) translate(97 208) translate(890 196) translate(92 12)"
              />
              <g
                transform="translate(-1079 -416) translate(97 208) translate(890 196) translate(92 12) translate(1.333 1.333)"
              >
                <mask id="1p7u9ylfdb" fill="#fff">
                  <use xlink:href="#s3eo6635la" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#s3eo6635la" />
                <g fill="#90A4AE" mask="url(#1p7u9ylfdb)">
                  <path
                    d="M0 0H16V16H0z"
                    transform="translate(-1.333 -1.333)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
